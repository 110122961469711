<template>
  <nav
    class="flex items-center justify-center col-span-12 select-none text-text-base"
    :class="{
      '!cursor-not-allowed opacity-50 [&_*]:pointer-events-none': disabled,
    }"
  >
    <BasicButtonIcon
      :icon="'fas fa-chevron-left'"
      classes="hover:cursor-pointer mr-sm"
      :plain="true"
      @click="onDecrement"
    />
    <div
      v-if="showPages[0] !== 1"
      class="mx-2xs text-sm w-[2rem] leading-[2rem] text-center text-text-base bg-white rounded-full entry"
      @click="onPageClicked(1)"
    >
      ...
    </div>
    <div
      v-for="(p, idx) in showPages"
      :key="'' + p + idx"
      class="entry mx-2xs text-sm w-[2rem] leading-[2rem] text-center text-text-base bg-white rounded-full cursor-pointer"
      :class="{
        'entry--active': typeof p === 'number' && p == currentPage,
      }"
      @click="typeof p === 'number' && onPageClicked(p)"
    >
      {{ p }}
    </div>
    <div
      v-if="showPages[showPages.length - 1] !== pages"
      class="mx-2xs text-sm w-[2rem] leading-[2rem] text-center text-text-base bg-white rounded-full entry"
      @click="onPageClicked(pages)"
    >
      ...
    </div>
    <BasicButtonIcon
      :icon="'fas fa-chevron-right'"
      classes="hover:cursor-pointer ml-sm"
      :plain="true"
      @click="onIncrement"
    />
  </nav>
</template>

<script setup lang="ts">
import BasicButtonIcon from '@/components/components/core/basicButtonIcon/basic-button-icon.vue';
import { SSR_safe_mq_breakpointIsMobile } from '~/injectionSymbols';

const props = defineProps({
  pages: {
    type: Number,
    required: true,
  },
  modelValue: {
    type: Number,
    default: 1,
  },
  doesLoop: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();

const currentPage = ref(props.modelValue);

const isMobile = inject(SSR_safe_mq_breakpointIsMobile);
const maxItems = isMobile.value ? 4 : 6;

const showPages = computed(() => {
  if (props.pages <= maxItems)
    return Array.from({ length: props.pages }, (_, i) => i + 1);
  const pages = [];

  if (currentPage.value < (maxItems + 1) / 2) {
    for (let i = 1; i <= props.pages && pages.length < maxItems; i++) {
      pages.push(i);
    }
  } else if (currentPage.value > props.pages - Math.ceil(maxItems / 2)) {
    for (let i = props.pages - maxItems + 1; i <= props.pages; i++) {
      pages.push(i);
    }
  } else {
    const items = Math.ceil(maxItems / 2) - 1;
    for (
      let i = currentPage.value - items;
      i <= currentPage.value + items;
      i++
    ) {
      pages.push(i);
    }
  }
  return pages;
});

function onIncrement() {
  if (props.disabled) return;
  currentPage.value =
    currentPage.value === props.pages
      ? props.doesLoop
        ? 1
        : currentPage.value
      : currentPage.value + 1;
}

function onDecrement() {
  if (props.disabled) return;
  currentPage.value =
    currentPage.value === 1
      ? props.doesLoop
        ? props.pages
        : currentPage.value
      : currentPage.value - 1;
}

function onPageClicked(page: number) {
  if (props.disabled) return;
  currentPage.value = page;
}

watch(currentPage, (newValue) => emit('update:modelValue', newValue));
</script>
<style scoped lang="postcss">
.entry--active,
.entry:hover {
  @apply bg-primary-base text-white ease-in duration-300;
}
</style>
